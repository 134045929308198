<template>
  <modal
    transition="nice-modal-fade"
    width="680"
    :adaptive="true"
    :reset="true"
    height="auto"
    :name="modalName"
    @before-open="beforeOpen"
  >
    <div class="modal--wrapper">
      <div class="back--wrapper" v-if="$mq === 'xs'">
        <div class="back--button" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        {{ $t('booking.modal.acceptBook.title') }}
      </div>
      <SectionLoader :show="isLoading" />
      <section class="modal--container accept--survey-container" v-show="step === 1">
        <div class="title--text">
          {{ $t('booking.modal.acceptBook.message') }}
        </div>
        <div class="date--time" v-if="modalData">
          <div class="single--row">
            <div class="left--col">{{ $t('general.startFrom') }}</div>
            <div class="right--col">{{ startDate }}</div>
          </div>
          <div class="single--row">
            <div class="left--col">{{ $t('general.until') }}</div>
            <div class="right--col">{{ endDate }}</div>
          </div>
          <div class="single--row">
            <div class="left--col">{{ $t('general.rentDuration') }}</div>
            <div class="right--col">{{ modalData.duration }}</div>
          </div>
          <div class="single--row">
            <div class="left--col">{{ $t('general.bookingFee') }}</div>
            <div class="right--col">{{ modalData.final_price }}</div>
          </div>
        </div>
        <hr class="separator" />
        <div class="d-flex justify-content-between" :class="{ 'flex-wrap': $mq === 'xs' }">
          <div class="btn btn-danger main--btn" @click="prevStep">
            {{ $t('booking.modal.acceptBook.rejectBtn') }}
          </div>
          <div
            v-if="modalData"
            v-show="step !== 3"
            class="btn btn-primary main--btn"
            @click="nextStep"
          >
            {{
              modalData.allow_initiate_agreement
                ? $t('booking.modal.acceptBook.next')
                : $t('booking.modal.acceptBook.acceptBtn')
            }}
          </div>
        </div>
      </section>
      <section class="modal--container accept--survey-container" v-show="step === 2">
        <div class="title--text">
          {{ $t('booking.modal.acceptBook.agreement') }}
        </div>
        <div class="desc agreement--type-radio">
          <div class="custom-control custom-radio mb-4">
            <input
              type="radio"
              id="agreement_type_1"
              name="agreement_type_1"
              v-model="agreementType"
              value="none"
              class="custom-control-input"
            />
            <label class="custom-control-label" for="agreement_type_1">{{
              $t('booking.modal.acceptBook.noAgreement')
            }}</label>
          </div>
          <div class="custom-control custom-radio mb-4">
            <input
              type="radio"
              id="agreement_type_2"
              name="agreement_type_2"
              v-model="agreementType"
              value="default"
              class="custom-control-input"
            />
            <label class="custom-control-label" for="agreement_type_2">{{
              $t('booking.modal.acceptBook.useDefaultAgreement')
            }}</label>
          </div>
          <div class="custom-control custom-radio mb-4">
            <input
              type="radio"
              id="agreement_type_3"
              name="agreement_type_3"
              v-model="agreementType"
              value="custom"
              class="custom-control-input"
            />
            <label class="custom-control-label" for="agreement_type_3">{{
              $t('booking.modal.acceptBook.useCustomAgreement')
            }}</label>
          </div>
        </div>
        <div class="col-12 py-0" v-if="!customAgreementData">
          <div
            class="mt-2"
            :class="{ 'has-error': validation.hasError('customAgreement') }"
            v-if="agreementType === 'custom'"
          >
            <div class="d-flex align-items-center" :class="{ 'flex-wrap': $mq === 'xs' }">
              <div class="col" v-if="customAgreement && customAgreement.file">
                {{ customAgreement.file.name }}
              </div>
              <img
                src="@/assets/img/icons/trash.svg"
                @click="removeAgreement"
                v-if="customAgreement && customAgreement.file"
                class="trash--button col-auto"
              />
            </div>
            <button type="button" class="btn btn-primary mx-auto mb-2" @click="uploadAgreement">
              {{ $t('booking.uploadAgreement') }}
            </button>
            <vue-dropzone
              v-show="false"
              ref="agreementDropzone"
              id="dropzone"
              :options="dropzoneOptions"
              @vdropzone-sending="sending"
              @vdropzone-success="success"
            />
            <span class="text-center val-error" v-if="validation.hasError('customAgreement')">{{
              validation.firstError('customAgreement')
            }}</span>
          </div>

          <div class="alert alert-warning mt-4" role="alert" v-if="agreementType === 'custom'">
            *{{ $t('booking.uploadAgreementTerms') }}
          </div>
        </div>

        <hr class="separator" />
        <div class="d-flex justify-content-between" :class="{ 'flex-wrap': $mq === 'xs' }">
          <div class="btn btn-primary--outline main--btn" @click="prevStep">
            {{ $t('general.btn.back') }}
          </div>
          <div
            v-if="modalData"
            v-show="step !== 3"
            class="btn btn-primary main--btn"
            @click="nextStep"
          >
            {{
              agreementType === 'default'
                ? $t('general.btn.previewAgreement')
                : $t('booking.modal.acceptBook.acceptBtn')
            }}
          </div>
        </div>
      </section>

      <section class="modal--container accept--survey-container" v-show="step === 3">
        <div class="title--text">{{ $t('booking.digitalAgreementPreview') }}</div>
        <hr />
        <div v-html="digitalSignPreview" class="preview--wrapper"></div>
        <hr class="separator" />
        <div class="d-flex justify-content-between" :class="{ 'flex-wrap': $mq === 'xs' }">
          <div class="btn btn-primary--outline main--btn" @click="prevStep">
            {{ $t('general.btn.back') }}
          </div>
        </div>
      </section>
      <button type="button" class="close" @click="close" v-if="$mq !== 'xs'">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>

<script>
import ModalMixin from '../mixins/index';
import HelperMixin from '@/mixins/helpers';
import { Validator } from 'simple-vue-validator';
import { mapState } from 'vuex';
// import Cleave from 'vue-cleave-component';
const SectionLoader = () => import('@/components/content-loading/section-loading');
import vue2Dropzone from 'nuxt-dropzone';

export default {
  name: 'rent-accept-modal',
  mixins: [ModalMixin, HelperMixin],
  components: {
    // Cleave,
    SectionLoader,
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      cleaveOption: {
        prefix: '',
        numeral: true,
        numeralPositiveOnly: true,
        noImmediatePrefix: true,
        rawValueTrimPrefix: true,
        numeralDecimalMark: ',',
        delimiter: '.',
        numeralIntegerScale: 16,
        numeralDecimalScale: 0,
      },
      modalName: 'rent-accept-modal',
      modalData: null,
      startDate: '',
      endDate: '',
      actionType: 'RENT_TRANSACTION',
      step: 1,
      digitalSignPreview: '',
      // useDigitalAgreement: true
      dropzoneOptions: {
        url: `${this.$store.state.global.apiUrl}/api/v2/file_content/upload_pdf`,
        maxFilesize: 10,
        acceptedFiles: '.pdf',
        paramName: 'file',
      },
    };
  },
  validators: {
    customAgreement(value) {
      if (this.agreementType === 'custom') {
        return Validator.value(value).required(this.$i18n.t('errors.customAgreement.required'));
      } else {
        return Validator.value(value);
      }
    },
  },
  computed: {
    ...mapState({
      customAgreementData: state => state.v2.rentTransaction.acceptRequest.customAgreementData,
    }),
    isLoading: {
      get() {
        return this.$store.state.v2.rentTransaction.acceptRequest.isLoading;
      },
      set(val) {
        this.$store.commit('v2/rentTransaction/acceptRequest/SET_IS_LOADING', val);
      },
    },
    customAgreement: {
      get() {
        return this.$store.state.v2.rentTransaction.acceptRequest.customAgreement;
      },
      set(val) {
        this.$store.commit('v2/rentTransaction/acceptRequest/SET_CUSTOM_AGREEMENT', val);
      },
    },
    agreementType: {
      get() {
        return this.$store.state.v2.rentTransaction.acceptRequest.agreementType;
      },
      set(val) {
        this.$store.commit('v2/rentTransaction/acceptRequest/SET_AGREEMENT_TYPE', val);
      },
    },
  },

  methods: {
    async beforeOpen(event) {
      this._closed();
      this.step = 1;
      this.$store.commit('v2/rentTransaction/acceptRequest/RESTORE_INITIAL_STATE');
      this._applicationUuid = event.params.uuid;
      this.$store.commit('v2/rentTransaction/acceptRequest/SET_UUID', event.params.uuid);
      console.log('weiihh');
      await this._fetchApplicationData();
      // Split between start date and end date from move date string.
      if (this.modalData && this.modalData.move_date) {
        let splitMoveDate = this.modalData.move_date.split('-');
        if (splitMoveDate && splitMoveDate.length === 2) {
          this.startDate = splitMoveDate[0].trim();
          this.endDate = splitMoveDate[1].trim();
        } else {
          this.startDate = this.modalData.move_date;
        }
      }
    },
    close() {
      this.$modal.hide(this.modalName);
    },
    prevStep() {
      if (this.step > 1) {
        this.step--;
      } else {
        this.reject();
      }
    },
    async nextStep() {
      console.log('STEP', this.step);
      if (this.step === 1 && this.modalData.allow_initiate_agreement) {
        const valid = await this.$validate();
        if (valid) this.step++;
      } else {
        if (this.agreementType === 'default') {
          this.$modal.show('rent-digital-agreement-modal', {
            uuid: this._applicationUuid,
            actionType: this.actionType,
          });
          this.close();
        } else {
          await this.accept();
        }
      }
    },
    validate() {
      let validationArray = [];
      validationArray.push(this.$validate());
      return Promise.all(validationArray).then(function(results) {
        return (
          results.filter(function(result) {
            return !result;
          }).length === 0
        );
      });
    },
    async reject() {
      try {
        this.isLoading = true;
        const result = await this.$store.dispatch(
          'v2/rentTransaction/cancelOwnerConfirmBooking',
          this.modalData.uuid,
        );
        if (result) {
          this.$emit('actionDone');
          this._close();
          this.$swal(
            this.$t('general.success'),
            this.$t('booking.swal.bookingModal.rejectedMsg'),
            'success',
          );
        }
        // eslint-disable-next-line no-empty
      } catch (e) {
      } finally {
        this.isLoading = false;
      }
    },
    async accept() {
      if (await this.validate()) {
        try {
          this.isLoading = true;
          const result = await this.$store.dispatch(
            'v2/rentTransaction/acceptRequest/postAcceptBooking',
          );
          if (result) {
            this.$emit('actionDone');
            this._close();
            this.$swal(
              this.$t('general.success'),
              this.$t('booking.swal.bookingModal.acceptedMsg'),
              'success',
            );
          }
          // eslint-disable-next-line no-empty
        } catch (e) {
        } finally {
          this.isLoading = false;
        }
      } else {
        this.scrollToErrorSection(this.$el);
      }
    },
    uploadAgreement() {
      this.$refs.agreementDropzone.dropzone.hiddenFileInput.click();
    },
    removeAgreement() {
      this.customAgreement = null;
    },
    previewAgreement() {
      window.open(this.modalData.agreement_url, '_blank');
    },
    // eslint-disable-next-line no-unused-vars
    sending(file, xhr, formData) {
      xhr.setRequestHeader('Authorization', `Bearer ${this.$store.state.global.token}`);
    },
    success(file, response) {
      console.log('SUCCESS UPLOAD AGREEMENT!', file);
      if (response.type === 'success') {
        this.customAgreement = {
          file: file,
          uuid: response.data.uuid,
        };
      }
    },
  },
};
</script>

<style scoped></style>
